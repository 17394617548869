.tabSheet{

}

.tabSheet .ant-tabs-content{

}
.mainData-model {
  width: 700px!important;
}
.tabSheet .ant-tabs-content .ant-tabs-tabpane{

}
.tree_chekbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #0e5987 !important;
  border-color: #005487;
}
.tree_chekbox .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner:after {
  border-color: rgb(255, 255, 255);
  -webkit-animation-name: none;
  animation-name: none;
}
