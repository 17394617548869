.news-block {
  .ant-row.news-feed {
    font-weight: 600;
    margin-bottom: 20px;
    height: 176px;

    .news-image {
      width: 100%;
      height: 100%;
      min-width: 220px;
    }

    .news-text {
      word-break: break-word;
      height: calc(100% - 21px);
      overflow: hidden;
      line-height: 1.6;
      display: -webkit-box;
      -webkit-line-clamp: 7;
      -webkit-box-orient: vertical;
    }

    .news-title {
      height: 21px;
      margin-bottom: 5px;
    }
  }

}

.birthday-man {
  .avatar {
    height: 100px;
    width: 100px;
  }
}
