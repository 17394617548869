html, body, #root {
  min-height: 100% !important;
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  background: #fafafa;
}
