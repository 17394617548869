.form-container {
  display: flex;
  flex-wrap: wrap;
  padding: 0;

  .element-property {
    margin: 16px;
  }
}

//CONTAINERS
.centered-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.vertical-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px;
}

.vertical-form-container > *:not(:last-child) {
  margin-bottom: 16px;
}