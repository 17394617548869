@import '/src/theme-vars';

.ant-dropdown-trigger {
  margin: 0 !important;
  //width: @default-element-property-width;
}

.default-type.ant-select {
  font-weight: 500 !important;
  font-size: @default-font-size !important;
  color: @primary-2 !important;
  width: 100%;
}

.ant-dropdown-trigger.default-type {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: @primary-3;

  > span {
    color: @primary-3 !important;
    font-size: @default-font-size;
  }
}

.ant-dropdown-menu {
  max-height: 200px;
  overflow-x: hidden;
}

.dropdown-for-settings-page {
  width: 625px !important;
}