@import "./theme-vars";
@import "resources/fonts/Montserrat/stylesheet.css";
@import "/src/app/hoc/CommonComponent/style";
@import "/src/app/components/Button/style";
@import "/src/app/common/FormContainer/style";
@import "/src/app/components/Content/style";
@import "/src/app/components/Input/style";
@import "/src/app/components/DefaultInputNumber/style";
@import "/src/app/components/Dropdown/style";
@import "/src/app/components/Dropdown/DropdownButton/style";
@import "/src/app/util/Notification/style";
@import "/src/app/header/UserPanel/style";
@import "/src/app/header/UserPanel/Notification/NotificationDropdown/style";
@import "/src/app/components/Table/style";
@import "/src/app/components/CourseCard/style";
@import "/src/app/common/StatusSteps/style";
@import "/src/app/components/CourseCarousel/style";
@import "/src/app/components/SearchInput/style";
@import "/src/app/pages/Course/style";
@import "/src/app/pages/MyCourse/style";
@import "/src/app/components/NoImage/style";
@import "/src/app/components/Test/style";
@import "/src/app/components/Carousel/style";
@import "/src/app/pages/MyEducation/style";
@import "/src/app/pages/Books/style";
@import "/src/app/home/timeline/style";
@import "/src/app/home/style";
.additional-panel {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.ant-layout-header {
  background: #fff!important ;
  box-shadow: inset 0px -1px 0px #E0E0E0;
}

.ant-layout-header .logo {
  min-width: 200px;
  width: 200px;
  max-width: 200px;
  padding-left: 20px;
}

.ant-layout-header .search-container {
  width: 30%;
}

.header-user-dropdown i {
  margin: 5px;
}


.ant-menu .ant-menu-item {
  white-space: normal !important;
  line-height: normal !important;
  padding-top: 11px !important;
  padding-bottom: 11px !important;
}

.ant-menu .ant-menu-item a {
  display: flex;
  align-items: center;
}

.ant-layout-sider .ant-menu.side-menu.ant-menu-light.ant-menu-root.ant-menu-inline {
  //box-shadow: inset -1px 0px 0px #E0E0E0 !important;
  border-right: 1px solid #E0E0E0 !important;;
}

.ant-menu .ant-menu-item-selected a,
.ant-menu .ant-menu-item.ant-menu-item-active a,
.ant-menu .ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-active .ant-menu-submenu-title,
.ant-menu .ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-selected .ant-menu-submenu-title {
  color: @primary-2 !important;
}

.ant-menu .ant-menu-submenu-title {
  box-shadow: inset 0px -1px 0px #E0E0E0;
  //margin-bottom: 0 !important;
}

//CARD FORM
@form-vertical-label-padding: 0 !important;

//FORM
.ant-form-item-label {
  line-height: normal !important;
}

.ant-col {
  min-height: auto;
}

.data-form {
  .ant-row {
    margin: 12px;
  }
}

.compact-form {
  .ant-form-item {
    margin-bottom: 8px !important;
  }
}

.card-actions-block {
  margin: 20px 30px;

  * {
    margin-right: 10px;
  }
}

.form-row {
  width: 100%;

  > * {
    padding-right: 20px !important;
  }
}
.myProfile-card{
  margin: 20px 10px!important;
}
.myProfile-card__left{
  width: 20%;
}
.myProfile-card__left .myProfile-card{
  width: 100%;
}
.content-header {
  padding: 40px 40px 0px 40px;
}

.page-body {
  width: 100%;
  padding: 0;
  padding-left: 20px!important;
}

.ant-card-body {
  padding: 0 !important;
  overflow-y: auto;
}

img.ant-menu-item-icon {
  height: 14px;
  width: 14px;
  margin: 3px;
}

.ant-menu.side-menu {
  li.ant-menu-item {
    display: flex;
    align-items: center;
  }
}

.button-icon {
  display: flex !important;
  align-items: center !important;

  > svg {
    margin: 3px;
  }
}

.button-group {
  display: flex;
  align-items: center;
  margin: 10px 0;

  > *:not(:last-child) {
    margin-right: 10px;
  }
}

@media (max-width: 636px) {
  .additional-panel {
    display: none !important;
  }
}

//TABLE
.ant-table-thead {
  > tr {
    > th {
      font-weight: 600 !important;
    }
  }
}

.ant-table-tbody {
  > tr {
    > td {
      font-weight: 500 !important;
    }
  }
}

.centered-flex-container {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.between-flex-container {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
}

.default-link {
  color: @primary-1;
  text-decoration: underline;
}

.default-font {
  font-size: 14px !important;
  color: @primary-2 !important;
}

//RATE
@rate-star-color: @primary-1 !important;

.header-default {
  font-size: 18px;
  font-weight: 600;
  color: @primary-2;
}

.large-text {
  color: #005487 !important;
  font-weight: 700;
}

.card-actions-container {
  .ant-card-actions {
    display: flex;
    justify-content: flex-end;

    li {
      width: auto !important;
      margin-left: 10px;
      margin-right: 10px;
    }
  }
}

.ant-list-item-meta-title {
  color: @primary-2 !important;
}

.ant-btn {
  > .anticon {
    &:nth-child(1) {
      margin-right: 5px;
    }

    &:nth-child(2) {
      margin-left: 5px;
    }
  }

  &.b-btn {
    font-size: 14px !important;
    font-family: Arial, sans-serif;
    font-weight: normal !important;
  }
}

.mb-0 {
  margin-bottom: 0 !important;
}

.kzm-tree-table {
  overflow-x: auto;

  .ant-table {
    border: none !important;

    .ant-table-content {
      > .ant-table-body {
        margin: 0 !important;

        > table {
          border-collapse: collapse;
          font-family: Arial, sans-serif;
          letter-spacing: normal !important;

          thead {
            tr {
              th {
                white-space: nowrap !important;
                background: #FAFAFA !important;
                border: 1px solid #e8e8e8 !important;

                .ant-table-column-title {
                  font-size: 14px;
                }
              }
            }
          }

          tbody {
            tr {
              td {
                border-radius: 0 !important;
                white-space: nowrap !important;
                font-size: 13px;
                border: 1px solid #e8e8e8 !important;
                transition: none !important;

                .ant-table-row-expand-icon {
                  width: 14px;
                  height: 14px;
                }
              }

              &:nth-child(odd):not(:hover):not(.ant-table-row-selected) > td {
                background: white !important;
              }

              &:hover > td, &:focus > td {
                background: rgb(230, 247, 255) !important;
              }
            }
          }
        }
      }
    }
  }
}

.card-actions-container .ant-card-actions li {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.card-actions-container .ant-card-actions li button {
  margin-left: 10px !important;
  margin-right: 10px !important;
}

@radio-dot-color: @primary-1 !important;
@checkbox-color: @primary-1 !important;

.break-words {
   word-wrap: break-word;
   white-space: initial;
}
.cba-form-item {
  display: flex;
  justify-content: center;
  label{
    display:none;
  }
}
.comment-block {
  .ant-list-item-meta-description {
    word-wrap: break-word;
    overflow-wrap: break-word;
    word-break: break-word;
  }
}

.underline {
  text-decoration: underline !important;
}

.total-body-height {
  height: calc(100% - 144px);
}

.asr-table-field {
  display: flex;
  label{
    display:none;
  }
}

.underline {
  text-decoration: underline !important;
}

.total-body-height {
  height: calc(100% - 144px);
}
.ant-menu-item{
  display: inline-flex!important;
}
.menu-icon__text:hover{
  .menu-icon__text span{
    transition: all .5s ease-in-out;
    color: black;
  }
}
.d{
  color:#cdf5ff59
}
.ant-menu-item:hover{
  background-color:#a887be4b !important;
}
.ant-layout-sider-trigger{
  background:#e6f7ff!important ;
  & > i{
    color: black;
    font-size: 20px;
  }
}
.menu-icon__text{
  transition: all .5s ease-in-out;

  & span{
    transition: all .5s ease-in-out;
    color: #8c8c8c;
  }

}
.ant-menu{
  display: flex;
  flex-direction: column;
}
.ant-menu-hidden{
  display: none!important;
}
.ant-menu-submenu-popup{
  background: rgba(0, 0, 0, 0)!important;
}
.ant-menu-submenu-placement-rightTop .ant-menu-vertical{
  background: rgba(0, 0, 0, 0.73) !important;
}
.ant-menu-sub{
  &:hover{
    .ant-menu-item-active{
      background: #0e5987;
    }
    .ant-menu-item-active > .menu-icon__text span{
      color: #f8f6f6 ;
    }
  }
}
.content-container{
  width: 99% !important;
}