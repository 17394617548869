.course-card {
  width: 200px;
  display: inline-block;
  text-align: center;
  padding: 10px !important;
  box-shadow: 0 3px 24px rgba(0, 0, 0, 0.1) !important;
  border: none !important;
  margin: 30px !important;

  img {
    border-radius: 4px !important;
  }

  .course-card-img {
    height: 180px !important;
  }

  .ant-card-cover {
    position: relative;
    height: 180px;
    display: flex;
    justify-content: center;
    align-items: center;

    .course-icon {
      position: absolute;
      top: 0;
      width: 20px;
    }

    .left-icon {
      left: 0;
    }

    .right-icon {
      right: 0;
    }
  }


  .ant-card-meta {
    margin-top: 10px;

    .ant-card-meta-title {
      display: -webkit-box !important;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
      height: 66px;
      font-size: 11px;
    }
  }
}

.course-card:hover {
  box-shadow: 0 3px 24px rgba(0, 84, 135, .5) !important;
}

.panel-logo {
  width: auto;
  height: 180px;
}