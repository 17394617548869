@import "/src/theme-vars";


.course-logo {
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-direction: column;

  .play-block {

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 100px;

    .play-icon {
      background: #2293e1;
      width: 70px;
      height: 70px;

      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      cursor: pointer;

      .triangle-icon {
        width: 0;
        height: 0;
        border-top: 15px solid transparent;
        border-bottom: 15px solid transparent;
        border-right: 0;
        border-left: 30px solid white;
        margin-left: 5px;
      }
    }

    .play-icon:hover {
      .triangle-icon {
        border-top: 20px solid transparent;
        border-bottom: 20px solid transparent;
        border-left: 40px solid white;
        margin-left: 10px;
      }
    }

    .play-icon:active {
      .triangle-icon {
        border-left-color: @follow-button-color;
      }
    }

    .play-text {
      display: block;
      height: 2em;
      font-size: 2em;
    }
  }
}

.course-section-item {
  border: 2px solid @border-color;
  border-radius: @default-border-radius;
  cursor: pointer;

  //display: flex;
  //align-items: center !important;

  .ant-list-item-meta-avatar {
    margin-left: 10px;
  }

  @ant-list-item-meta-title-margin: 14px;

  .ant-list-item-meta-title {
    margin: @ant-list-item-meta-title-margin;
    text-align: center;
    position: relative;
    //display: flex;
    //align-items: center;
    //justify-content: center;

    @done-font-size: 24px;

    .done {
      position: absolute;
      font-size: 24px !important;
      left: calc(0px - @ant-list-item-meta-title-margin - (@done-font-size / 2));
      top: calc(0px - @ant-list-item-meta-title-margin - (@done-font-size / 2));
    }
  }
}

.ant-modal-body {
  .fullscreen-icon {
    text-align: right;

    >i {
      font-size: 16px;
      text-align: right;

      transition: 0.3s;
    }

    >i:hover {
      font-size: 24px;
    }
  }

  .modal-body {
    margin-top: 20px;
    padding: 10px;
  }
}

.course-section-item.selected,
.course-feedback-sections .course-section-item.selected {
  border-color: @primary-1;
}

.course-section-modal {
  transition: 0.5s !important;
  top: 0 !important;

  .course-section-modal-body {
    width: 100%;
    height: 500px;
  }
}

.course-section-modal.fullscreen {
  width: 100% !important;
  height: 100%;
  padding: 0;

  .ant-modal-content {
    height: 100%;

    .ant-modal-body {
      height: 100%;

      .ant-card {
        height: 100%;

        .ant-card-body {
          height: calc(100% - 57px);

          .course-section-modal-body {
            height: calc(100% - 27px);
          }
        }
      }
    }
  }
}


.course-section-item:hover {
  border-color: @primary-4;
}

.course-section-item:active,
.course-section-item:hover:active {
  border-color: @primary-3;
}

.course-feedback-sections .course-section-item {
  border-color: @follow-button-color;
}

.course-feedback-sections .course-section-item:hover {
  border-color: @follow-button-hover-color;
}

.picker-btn {
  border: 1px solid #d9d9d9 !important;
  color: black !important;
  border-left: none !important;
  padding-left: 4px !important;
}

.budget-request-form {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.budget-request-form-input {
  width: 70% !important;
  display: flex !important;
  flex-direction: row;
  justify-content: flex-end;
}

.delete-budget-request {
  color: #fff !important;
  padding-left: 4px !important;
}

.delete-budget-request-disabled {
  color: #ccc !important;
  border: solid 1px #ccc !important;
  padding-left: 4px !important;
}

.budget-info-field {
  margin-bottom: 12px;
  display: inline-block;
  width: 300px;
}

.add-group-persons-btn {
  color: black !important;
  border: 1px solid #ccc !important;
  padding-left: 10px;
  padding-right: 5px;
  margin-left: 15px;
}

.edit-budget-request-btn {
  color: black !important;
}

.delete-row-btn {
  color: #40a9ff !important
}

.forecast-label {
  font-size: 16px;
  font-weight: 600;
  vertical-align: middle;
  margin-left: 10px;
}

.send-for-revision-btn {
  border: 1px solid #d9d9d9;
  background-color: #fae85c !important;
  color: black !important;
}
