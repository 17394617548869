.ant-table-empty .ant-table-body::-webkit-scrollbar {
  width:0!important;
  height: 0!important;
}
.ant-table-empty .ant-table-body:hover::-webkit-scrollbar {
  width: 4px!important;
  height: 4px!important;
}
.ant-table-empty .ant-table-body:hover::-webkit-scrollbar-track {
  border-radius: 100px!important;
}

.ant-table-empty .ant-table-body:hover::-webkit-scrollbar-thumb {
  background-color: gray!important;
  border-radius: 100px!important;
}
.ant-table-body::-webkit-scrollbar {
  width: 0!important;
  height: 0!important;
}
 .ant-table-body:hover::-webkit-scrollbar {
  width: 4px!important;
  height: 4px!important;
}
 .ant-table-body:hover::-webkit-scrollbar-track {
  border-radius: 100px!important;
}

 .ant-table-body:hover::-webkit-scrollbar-thumb {
  background-color: gray!important;
  border-radius: 100px!important;
}

.ant-tree__hierarchy::-webkit-scrollbar {
  width: 4px!important;
  height: 4px!important;
}
.ant-tree__hierarchy::-webkit-scrollbar-track {
  border-radius: 100px!important;
}

.ant-tree__hierarchy::-webkit-scrollbar-thumb {
  background-color: gray!important;
  border-radius: 100px!important;
}

.ant-tree::-webkit-scrollbar {
  width: 4px!important;
  height: 4px!important;
}
.ant-tree::-webkit-scrollbar-track {
  border-radius: 100px!important;
}

.ant-tree::-webkit-scrollbar-thumb {
  background-color: gray!important;
  border-radius: 100px!important;
}


.budget-tree::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.budget-tree:hover::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.budget-tree:hover::-webkit-scrollbar-track {
  border-radius: 100px;
}

.budget-tree:hover::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 100px;
}

.ant-layout-sider::-webkit-scrollbar {
  width: 0;
  height: 0;
  overflow-x: hidden;
}
.ant-layout-sider:hover::-webkit-scrollbar {
  width: 6px;
  height: 0;
}

.ant-layout-sider:hover::-webkit-scrollbar-track {
  border-radius: 100px;
  max-height: 40px;
}

.ant-layout-sider:hover::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 100px;
   max-height: 40px;
}

.main-layout {
  min-height: 100vh !important;
  height: 100%;
}
.h-header{
  position: fixed;
  z-index: 1000;
  width: 100%;
  background: #fff!important;

}
.h-header::before{
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 64px;
  top: 0;
  left: 0;
  background: -webkit-gradient(linear, right top, left top, from(#4EC1E0), to(rgba(78, 193, 224, 0))) !important;
}
.selected-column-invisible .ant-table-selection-column{
  display: none;
}
.tableSelectCheckboxOverride .ant-table-selection-column{
  display: none;
}
.ant-table-filter-dropdown-link{
  padding: 3px!important;
}
/*.narrow-layout {*/
/*  max-width: 1024px;*/
/*  margin: auto;*/
/*}*/

/*.narrow-layout.ant-card {*/
/*  max-width: 1024px;*/
/*  margin: auto;*/
/*}*/

/* Popup container - can be anything you want */
.popup {
  position: relative;
  display: inline-block;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.emplyee_checkbox > .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after{
  border-color: #fff!important;
}
/* The actual popup */
.popup .popuptext {
  visibility: hidden;
  width: 400px;
  background-color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 8px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -80px;
}

/* Popup arrow */
.popup .popuptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

/* Toggle this class - hide and show the popup */
.popup .show {
  visibility: visible;
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
}

/* Add animation (fade in the popup) */
@-webkit-keyframes fadeIn {
  from {opacity: 0;}
  to {opacity: 1;}
}

@keyframes fadeIn {
  from {opacity: 0;}
  to {opacity:1 ;}
}

.color-blue{
  color: blue;
}

.display-contents-span > span{
  display: contents !important;
}

span .ant-checkbox-disabled .ant-checkbox-inner{
  background-color: #f5f5f5 !important;
}


.Resizer {
  background: #000;
  opacity: .2;
  z-index: 1;
  box-sizing: border-box;
  background-clip: padding-box;
}

.Resizer:hover {
  transition: all 2s ease;
}

.Resizer.horizontal {
  height: 11px;
  margin: -5px 0;
  border-top: 5px solid rgba(255, 255, 255, 0);
  border-bottom: 5px solid rgba(255, 255, 255, 0);
  cursor: row-resize;
}

.Resizer.horizontal:hover, .Resizer.horizontal.resizing {
  border-top: 5px solid rgba(0, 0, 0, 0.5);
  border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.vertical {
  width: 11px;
  margin: 0 -5px;
  border-left: 5px solid rgba(255, 255, 255, 0);
  border-right: 5px solid rgba(255, 255, 255, 0);
  cursor: col-resize;
}

.Resizer.vertical:hover, .Resizer.vertical.resizing {
  border-left: 5px solid rgba(0, 0, 0, 0.5);
  border-right: 5px solid rgba(0, 0, 0, 0.5);
}

.DragLayer {
  z-index: 1;
  pointer-events: none;
}

.DragLayer.resizing {
  pointer-events: auto;
}

.DragLayer.horizontal {
  cursor: row-resize;
}

.DragLayer.vertical {
  cursor: col-resize;
}

/* File upload field */
.ant-upload-disabled {
  opacity: 0.5;
}

.main-layout.ant-layout{
  height: auto;
}
div .ant-tooltip-inner {
  white-space: pre-wrap;
}
div.ant-card-body{
 overflow: hidden;
}
.spin-center {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.orgVacancy{
  margin-left:20px;
  margin-top:20px;
}
.news-block .ant-row.news-feed {
  height: auto!important;
}
.news-block .ant-row.news-feed .news-title {
  height: auto!important;
}

.default-form-item{
  display: flex;
  justify-content: start;
  flex-direction: row-reverse;
}
.default-form-item::before{
  display: none !important;
}
.default-form-item::after{
  display: none !important;
}
.default-form-item .ant-form-item-label{
  margin-left: 14px;
  text-align: start;
}
.concoureRequest_form .ant-form-item-label{
  margin-left: 14px!important;
}
.competencyBasedAssessment_detail__items{
  padding: 0 10px;
  display: flex;
  flex-direction: column;
}
.competencyBasedAssessment_detail__items > input{
  margin-top: 12px;
}
.competence_table .ant-table-selection-column{
  display: none!important;
}
.competence_table .ant-table-row-cell-ellipsis .ant-table-row-cell-ellipsis .ant-table-column-title {
  white-space: normal !important;
  min-width: 700px;
}


.personOtherInfo_table .ant-table-column-has-actions{
  width: 50%;
}

.competence_table .ant-table-tbody >tr{
  white-space: normal;
}
.competence_table .ant-table-tbody >tr>td:nth-child(2){
  min-width: 300px;
  max-width: 700px;
}
.cba_modal {
  width: 1200px!important;
}
.cba_modal  .ant-modal-content{
  width: 1200px;
}
.cba_modal  .ant-modal-close-x {
  display: none;
}
.cba-detail .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #0e5987 !important;
  border-color: #005487;
}
.cba-detail .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner:after {
  border-color: rgb(255, 255, 255);
  -webkit-animation-name: none;
  animation-name: none;
}
.cba-detail .indent-level-1{
  display: none;
}
.cba-detail .ant-table-selection-column{
  display: none!important;
}
.cba-detail  .ant-table-row-level-0 .ant-checkbox-wrapper{
  display: none!important;
}
.individualPlane_modal{
  width: fit-content!important;
}.individualPlane_modal .ant-modal-body{
  padding: 14px 15px 14px!important;
}
.individualPlane_modal .anticon{
  display: none!important;
}
.individualPlane_modal .ant-modal-confirm-content{
 margin-left: 0!important;
}
.successors-checkbox{
  margin-left: 10px;
}
.successors-checkbox.ant-checkbox-wrapper{
  margin-left: 10px;
}
.successors-checkbox.ant-checkbox-wrapper>span>span.ant-checkbox-inner{
  border-color: #005487;
}


.myTeam-personData__checkbox  .ant-checkbox-disabled .ant-checkbox-inner {
  background-color: #005487 !important;
}
.myTeam-personData__checkbox .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner:after {
  border-color: rgb(241 241 241)!important;
  -webkit-animation-name: none;
  animation-name: none;
}
.cba-detail--modal{
  width: 90%!important;
}
.competence_table__history .ant-table-row-level-2 .ant-table-row-expand-icon {
  display: none!important;
}
.competence_table .ant-table-row-level-2 .ant-table-row-collapsed{
  display: none!important;
}
.competence_table__history .indent-level-1{
  display: none;
}
.competence_table__history .ant-table-tbody >tr{
  white-space: normal;
}
.competence_table__history .ant-table-tbody >tr>td:nth-child(2){
  min-width: 300px;
  max-width: 700px;
}
.competence_table__history .ant-table-selection-column{
  display: none!important;
}
.competence_table__history .ant-table-row-cell-ellipsis .ant-table-row-cell-ellipsis .ant-table-column-title {
  white-space: normal !important;
  min-width: 700px;
}
.personOtherInfo_table  .ant-table-column-sorter{
  display: none!important;
}
.between-flex-container{
  justify-content: space-between!important;
}

.between-flex-container , .centered-flex-container {
  display: flex!important;
  align-items: center!important;
}
.quick-access-service-container .quick-access-service .quick-access-service-icon img {
   height: 50px;
}
.quick-access-service-container .quick-access-service {
  color: #000;
  margin: 20px 0;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
}
.quick-access-service-container .quick-access-service .quick-access-service-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ant-menu{
  display: flex;
  flex-direction: column;
}

.ant-list-split::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.ant-list-split:hover::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.ant-list-split:hover::-webkit-scrollbar-track {
  border-radius: 100px;
}

.ant-list-split:hover::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 100px;
}
.requestHistory .ant-table{
  overflow-x: auto;
}
.myTeam-request .content-header{
  padding: 0 0 0 20px!important;
  margin: 0;
}
.myTeam-request .page-body{
  padding: 0!important;
}.myTeam-request .page-body .large-section{
  margin: 0!important;
   width: 98%;
}.myTeam-request .page-body .section-container {
  border: none;
  box-shadow: none;
}

.competence_table .ant-table-selection-column{
  display: none!important;
}
.competence_table .ant-table-row-cell-ellipsis .ant-table-row-cell-ellipsis .ant-table-column-title {
  white-space: normal !important;
  min-width: 700px;
}


.personOtherInfo_table .ant-table-column-has-actions{
  width: 50%;
}

.competence_table .ant-table-tbody >tr{
  white-space: normal;
}
.competence_table .ant-table-tbody >tr>td:nth-child(2){
  min-width: 300px;
  max-width: 700px;
}
.competence_table .indent-level-1{
  display: none;
}
.cba_modal {
  width: 800px!important;
}
.cba_modal  .ant-modal-content{
  width: 800px;
}
.cba_modal  .ant-modal-close-x {
  display: none;
}
.cba-detail .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #0e5987 !important;
  border-color: #005487;
}
.cba-detail .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner:after {
  border-color: rgb(255, 255, 255);
  -webkit-animation-name: none;
  animation-name: none;
}
.cba-detail .indent-level-1{
  display: none;
}
.cba-detail .ant-table-selection-column{
  display: none!important;
}
.cba-detail  .ant-table-row-level-0 .ant-checkbox-wrapper{
  display: none!important;
}
.individualPlane_modal{
  width: fit-content!important;
}.individualPlane_modal .ant-modal-body{
  padding: 14px 15px 14px!important;
}
.individualPlane_modal .anticon{
  display: none!important;
}
.individualPlane_modal .ant-modal-confirm-content{
 margin-left: 0!important;
}
.dis-button .cuba-data-table .buttons .button{
  color: rgba(0,0,0,0.25);
}
.absence-button[disabled], .dis-button--color[disabled]{
  color: rgba(0,0,0,25%)!important;
}
.btn-primary:not([disabled]) {
  background-color: #005487 !important;
  color: #fff!important;
}
.home-page-carousel{
  width: 100%;
  height: 100%;
  min-width: 220px;
  position: relative;
}
.carousel-next-arrow{
  margin: auto;
  position: absolute;
  top:50%;
  transform: translateY(-50%);
  right: -2%;
}
.carousel-prev-arrow{
  margin: auto;
  position: absolute;
  top:50%;
  transform: translateY(-50%);
  left: -2%;
}
.news-title-container{
  width: calc(100% - 10px);
  margin-left: 10px;
}
.layout-collapsed{
  margin-left: 70px;
  padding-left: 20px;
}
.layout-default{
  margin-left: 200px;
  padding-left: 0;
}
@media only screen and (max-width: 600px) {
  .section-container.large-section.hidden{
    padding: 0;
    margin: 5px;
    width:95%;
  }
  .carousel-next-arrow{
    display: none !important;
  }
  .carousel-prev-arrow{
    display: none !important;
  }
  .news-title-container{
    width: 100%;
  }
  .layout-collapsed{
    padding-left: 0;
  }
  .between-flex-container.quick-access-service-container{
    justify-content: space-between;
  }
  .quick-access-service{
    padding: 2px;
  }
  .quick-access-service-img{
    max-width: 100%;
  }
}

.buttonWccfReport{
  background: #0e5987!important;
}