.login-form {
  border-radius: 4px;
  background: #fff;
  padding: 32px 32px 0;
  min-width: 300px;
  max-width: 500px;
  /*width: 500px;*/
  box-shadow: 0 0 50px 0 rgba(0,0,0,0.2);
}

.login-form > .title {
  text-align: center;
  margin-bottom: 24px;
  font-size: 20px;
}

.login-form > .logo {
  display: block;
  margin: 0 auto 20px;
  height: 50px;
}

@media (max-width: 636px) {
  .login-form {
    width: 100%;
    height: 100%;
  }
}
