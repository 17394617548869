.dismissal-interview-page{
    padding: 2rem;

    .dismissal-interview-page__title{
        font-size: 1rem;
        font-weight: 600;
    }

    .ant-radio-group>.ant-card, .ant-checkbox-group>.ant-card {
        border: none;
    }    

    .dismissal-interview__close-btn{
        float: right;
    }

    .question-title{
        font-weight: 600;
    }
}
@overrideTheme: true; @import "/root/workspace/hruco.build.deploy.war/modules/front/src/theme.less";