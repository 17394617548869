.keyPosition-modal--reservist{
  width:70%!important;
}
.hover-list .anticon-user > svg{
  fill: #005487;
  width: 20px;
  height: 20px;
  top:0
}
.individual-input{
  color: #005487!important;
  font-weight: 500;
  text-decoration: underline;
}
.individual-input:hover{
  cursor: pointer;
}
.keyPosition-modal--reservist .ant-form-item{
  margin-bottom: 6px;
}
.keyPosition-table .ant-table-body{
  margin: 0!important;
}
.keyPosition-table .ant-table-row > td{
  text-align: start;
}
.keyPosition-table .ant-table-row > td > span{
  font-weight: 600;
}
.keyPosition-table .ant-table-row > td span{
  text-align: start;
}