.app-header {
  display: flex;
  justify-content: space-between;
  background: rgb(255, 255, 255);
  background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(168, 135, 190, 1) 50%);
}

.user-panel>.panelelement {
  margin: 6px;
}
