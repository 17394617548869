@import "/src/theme-vars";

@input-color: @primary-2 !important;
//@icon-color: @primary-1 !important;

.element-property {
  width: 244px;

  font-weight: 500;
  font-size: @default-font-size-header;
}

.element-property *:not(.element-header-name):focus {
  box-shadow: none !important;
  border-color: @primary-4 !important;
}

.element-property span {
  display: block;
  font-size: 12px !important;
  font-weight: 500;
}

.ant-select-disabled {
  font-size: @default-font-size !important;
  font-weight: 400;
  color: @primary-2 !important;
}