@import "/src/theme-vars";

.content-container {
  .content-header {
    color: @primary-2;
    font-size: 32px;
    line-height: 48px;
  }
}

.section-container:not(.p-none) {
  padding: 24px;
}

.section-container:not(.hidden) {
  box-shadow: 0 3px 24px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.page-body .section-container {
  display: inline-block;
  margin: 20px 40px;

  .section-header-container {
    margin-top: 24px;
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: 600;
    color: @primary-2;
  }
}

.large-section {
  width: calc(100% - 80px);
}