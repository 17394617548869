@import "/src/theme-vars";

.button {
  font-size: 12px !important;
  color: white !important;
  border: 0 !important;
  //padding: 12px 0 !important;
  width: auto;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 4px !important;
  font-weight: 600 !important;
  cursor: pointer !important;
  outline: none !important;
  justify-content: center !important;
  align-items: center !important;
}

.button > *,
.btn,
.btn > * {
  font-size: 12px !important;
  font-weight: 600 !important;
}

.btn-primary > i.icon {
  margin: 0 6px !important;
}

.btn-primary > i.icon svg {
  fill: white !important;
  width: 15px !important;
  height: 15px !important;
}

.btn-follow {
  background-color: white !important;
  border: 2px solid @follow-button-color !important;
  color: @primary-2 !important;
}

.btn-follow-disabled {
  color: rgba(0,0,0,.25) !important;
}

.btn-disabled {
  cursor: not-allowed !important;
}

.btn-follow:hover {
  background-color: @follow-button-hover-color !important;
}

.btn-follow:active {
  border-color: @follow-button-hover-color !important;
}

.ant-btn-primary:not([disabled]) {
  background-color: @primary-1 !important;
  border-color: @primary-1 !important;
}

.btn-primary:not([disabled]) {
  background-color: @primary-1 !important;
}

.btn-primary:not([disabled]):hover {
  background-color: @primary-1 !important;
}

.ant-btn-primary.revision:not([disabled]) {
  background-color: @revision-btn-color !important;
  border-color: @revision-btn-color !important;
}

.btn-primary.revision:not([disabled]) {
  background-color: @revision-btn-color !important;
}

.btn-primary.revision:not([disabled]):hover {
  background-color: @revision-btn-color !important;
}

.ant-btn-primary.reject:not([disabled]) {
  background-color: @reject-btn-color !important;
  border-color: @reject-btn-color !important;
}

.btn-primary.reject:not([disabled]) {
  background-color: @reject-btn-color !important;
}

.btn-primary.reject:not([disabled]):hover {
  background-color: @reject-btn-color !important;
}

.ant-btn-primary.cancel:not([disabled]) {
  background-color: @cancel-btn-color !important;
  border-color: @cancel-btn-color !important;
}

.btn-primary.cancel:not([disabled]) {
  background-color: @cancel-btn-color !important;
}

.btn-primary.cancel:not([disabled]):hover {
  background-color: @cancel-btn-color !important;
}

.button-for-settings-page {
  display: flex !important;
  width: 625px !important;
}