.individualPlane_top{
  display: flex;
  justify-content: center;
}
.individualPlane_top-items{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.individualPlane .content-container > h1 {
  padding: 40px 40px 0 40px;
}
.individualPlane_top-items p{
  margin-left: 10px;
}
.individualPlane_top__left{
 width: 40%;
  min-width: 50%;
  padding: 0 18px 0 18px;
  margin-bottom: 0;
}
.individualPlane_top__right{
  width: 40%;
  min-width: 50%;
  padding: 0 18px 0 18px;
  margin-bottom: 0;
}
.individualPlane_top-items> .ant-form-item {
  min-width: 70%;
  margin-left: 10px;
}
.individualPlane_top-items .ant-form-item-children{
  display: flex!important;
}
.individualPlane_top-items .anticon-apartment  svg{
  width: 1.8em!important;
  height: 1.8em!important;
}
.individualPlane_top-items .anticon:nth-child(1) {
  margin-right: 0 !important;
}
.individualPlane_top-items--chek{
  width: 100%;
  margin-bottom: 0;
}
.ant-row.ant-form-item.taskDetailModalItem{
  margin-bottom: 5px;
  margin-left: 19px;
  margin-right: 19px;
}
.ant-row.ant-form-item.taskSelect{
  width: 45%;
  margin-bottom:0;
}
.ant-row.ant-form-item.person-help{
  margin-left: 12px;
  margin-right: 12px;
  margin-bottom: 5px;
}
input.taskDate{
  width: 100%
}
.task-status{
  padding: 5px;
  width: 100%;
}
.task-options{
  text-align: center;
  width: 15%;
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center
}
.task-list-item{
  width: 15%;
  display: inline-flex;
  flex-direction: column;
  margin-right: 20px;
}
.task-main{
  width: 65%;
  display: inline-flex;
  flex-direction: column;
  justify-content: space-around
}
.task-types{
  width: 100%;
  display: inline-flex;
  justify-content: space-between
}
.task-list-item-container{
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.individualPlane .page-body{
  display: flex;
  align-items: center;
  justify-content: center;
}
.ant-form-item.task-detail-item{
  margin-bottom: 5px;
}
