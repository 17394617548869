.questionnaire-text {
  margin-top: 20px;
}

.quick-access-service-container {
  .quick-access-service {
    color: black;
    margin: 20px 0;
    display: flex;
    align-content: center;
    justify-content: center;
    flex-direction: column;

    .quick-access-service-icon {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        //width: 50px;
        height: 50px;
      }
    }
  }
}

.quick-access-service-name {
  text-align: center;
}