@import "/src/theme-vars";

.test-container.test-single-page {
  .question-container:not(:last-child) {
    margin-bottom: 20px !important;
  }
}

.test-section {
  color: black;
  display: none;

  .question-container {
    display: none;
  }

  .question-container.visible {
    display: block;
  }

  .test-section-title {
    font-size: 20px;
    text-align: center;
    font-weight: 500;
    margin: 10px;
  }

  .question-block:not(:first-child) {
    margin-top: 10px;
  }


  .answer-block {
    width: 100%;

    .card-test-answer-item {
      padding: 5px !important;
      margin: 5px 0 !important;
      width: 100%;
    }
  }

  .question-control-block {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    margin: 10px 0;

    .control {
      cursor: pointer;
    }
  }
}

.test-section.visible {
  display: block;
}
