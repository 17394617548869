@carousel-img-height: 450px;

.ant-carousel {
  height: 100%;
}

.image-content-container h1 {
  text-transform: uppercase;
  font-size: 50px;
}

.main-menu-carousel {
  width: 100%;
}

.items-carousel {
  height: @carousel-img-height;
  position: relative;
}

.items-carousel.bordered {
  border-top: 2px solid black;
  border-bottom: 2px solid black;
}

.carousel-bottom-menu {
  position: absolute;
  width: 100%;
  top: 50%;
}

.main-carousel-bottom-menu i:nth-child(1) {
  position: absolute;
  left: -12px;
}

.main-carousel-bottom-menu i:nth-child(2) {
  position: absolute;
  right: -12px;
}

.main-carousel-bottom-menu.courses i:nth-child(1) {
  transform: translateY(-260%);
  left: -5%;
}

.main-carousel-bottom-menu.courses i:nth-child(2) {
  transform: translateY(-260%);
  right: -5%;
}

.carousel-elements-group {
  display: flex !important;
  justify-content: left;
}

.main-carousel-bottom-menu {
  position: relative;
  width: 100%;
}

.main-carousel-bottom-menu-arrow {
  font-size: x-large;
  border-radius: 50%;
  transition: 0.3s;
  background-color: rgba(255,255,255,0.5);
  padding: 2px;
}

.main-menu-carousel-image-container {
  height: fit-content;
}

.main-menu-carousel-image {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-height: inherit;
  object-fit: fill;
  width: 2000px;
  height: @carousel-img-height;
}

@media (max-width: 2336px) {
  @carousel-img-height: 400px;
  @carousel-img-width: 1800px;

  .items-carousel {
    height: @carousel-img-height;
    position: relative;
  }

  .main-menu-carousel-image {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-height: inherit;
    object-fit: fill;
    width: @carousel-img-width;
    height: @carousel-img-height;
  }
}

@media (max-width: 2128px) {
  @carousel-img-height: 350px;
  @carousel-img-width: 1550px;

  .items-carousel {
    height: @carousel-img-height;
    position: relative;
  }

  .main-menu-carousel-image {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-height: inherit;
    object-fit: fill;
    width: @carousel-img-width;
    height: @carousel-img-height;
  }
}

@media (max-width: 1878px) {
  @carousel-img-height: 300px;
  @carousel-img-width: 1333px;

  .items-carousel {
    height: @carousel-img-height;
    position: relative;
  }

  .main-menu-carousel-image {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-height: inherit;
    object-fit: fill;
    width: @carousel-img-width;
    height: @carousel-img-height;
  }
}

@media (max-width: 1661px) {
  @carousel-img-height: 250px;
  @carousel-img-width: 1111px;

  .items-carousel {
    height: @carousel-img-height;
    position: relative;
  }

  .main-menu-carousel-image {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-height: inherit;
    object-fit: fill;
    width: @carousel-img-width;
    height: @carousel-img-height;
  }
}

@media (max-width: 1439px) {
  @carousel-img-height: 200px;
  @carousel-img-width: 888px;

  .items-carousel {
    height: @carousel-img-height;
    position: relative;
  }

  .main-menu-carousel-image {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-height: inherit;
    object-fit: fill;
    width: @carousel-img-width;
    height: @carousel-img-height;
  }
}

@media (max-width: 1216px) {
  @carousel-img-height: 150px;
  @carousel-img-width: 666px;

  .items-carousel {
    height: @carousel-img-height;
    position: relative;
  }

  .main-menu-carousel-image {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-height: inherit;
    object-fit: fill;
    width: @carousel-img-width;
    height: @carousel-img-height;
  }
}

//@media (min-width: 1084px) {
//  @carousel-img-height: 350px;
//
//  .items-carousel {
//    height: @carousel-img-height;
//    position: relative;
//  }
//
//  .main-menu-carousel-image {
//    display: block;
//    margin-left: auto;
//    margin-right: auto;
//    max-height: inherit;
//    object-fit: fill;
//    width: 100%;
//    height: @carousel-img-height;
//  }
//}