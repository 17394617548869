@import "../../../theme-vars";

.selection-download-book {
  width: 256px;
  margin-right: 10px !important;
  .ant-select-selection--single {
    //background-color: #005487
  }

  //color: white;
}