.ninebox-container-item:nth-child(7){
    position: relative;
}
.ninebox-container{
  grid-auto-rows: 1fr;
  font-size: 95%;
}
.ninebox-container-item:nth-child(4){
  position: relative;
}
.ninebox-container-item:nth-child(1){
  position: relative;
}
.ninebox-span{
  display: block;
  text-decoration: underline;
  margin: 0;
  padding: 0;
  text-align: left;
}
.selectTest{
  width: 200px;
}
.ninebox-span+.ninebox-span{
  margin-top: 5px;
}
.ninebox-container-item{
  padding: 10px;
  background-color: #bae7ff;
}
.li-element{
  writing-mode: vertical-rl;
  align-self: flex-end;
  transform: rotate(180deg);
}
