.person-medal-form-field {
  width: 100%;
}

.person-medal-form-item {
  width: 100%;
}

.cancel-btn {
  color: black !important;
  border: 1px solid #ccc !important;
}

.picker-btn {
  border: 1px solid #d9d9d9 !important;
  color: black !important;
  border-left: none !important;
  bottom: 1px;
  padding: 0 8px !important;
}

.filter-field {
  display: inline-block;
  margin-left: 10px;
}

.form-item-without-bottom-margin {
  margin-bottom: 0px !important;
}

.form-item-box {
  width: 90%;
}
.request-table-control-btn {
  margin: 1% !important;
  padding-left: 13px !important;
  padding-right: 9px !important;
}
.attention-block {
  padding: 10px;
  position: fixed;
  width: 25rem;
  height: 13rem;
  background-color: #ffbbb9;
  border: 1px solid #f17976;
  border-radius: 6px;
  font-weight: bold;
  top: 12%;
  right: 0;
  z-index: 100;
  overflow-y: auto;
}